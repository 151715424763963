import React, { Component } from "react";
import "./expt5.css";
import styled from "styled-components";

const Button = styled.button`
            background-color: #810b07;
            color: white;
            width: 200px;
            font-size: 20px;
            padding: 10px 60px;
            border-radius: 5px;
            margin: 10px 0px;
            cursor: pointer;
            `;
export default class Expt1 extends Component {
  render() {
    return (
      <div className="expt-template">
        <h1 className="expt-heading">5. Implementing Hidden Markov Model</h1>
        <h3 className="expt-sub-heading">
          <u>Aim</u>
        </h3>
        <p>
          The aim of this experiment is to understand the Hidden Markov Models, and analyse how they are different from the traditional HMM.
        </p>
        <h3 className="expt-sub-heading">
          <u>Theory</u>
        </h3>
        <p>
          Hidden Markov Models (HMMs) are statistical models used to model time-series data,
          where the underlying states of the system generating the data are not directly observable,
          but can only be inferred from the observed data. HMMs are widely used in many fields such as
          speech recognition, natural language processing, finance, bioinformatics, and more. <br></br>
          An HMM consists of a sequence of hidden states and a sequence of observed outputs.
          The hidden states are not directly observable, but are assumed to generate the observed outputs.
          Each hidden state in the sequence is assumed to depend only on the previous state,
          and the current observed output depends only on the current state. Therefore, an HMM can be thought of
          as a "memoryless" probabilistic model that can capture dependencies over time. <br></br>
          Two Main Questions in Markov-model are answered: <br></br>
          1. Probability of particular sequences of state z? <br></br>
          2. How do we estimate the parameter of state transition matrix A to maximize the likelihood of the observed sequence? <br></br>

        </p>

        <h3 className="expt-sub-heading">
          <u>Features</u>
        </h3>
        <p>
          Hidden Markov Models (HMMs) are a type of statistical model that are widely used in speech recognition, natural language processing, bioinformatics, and other fields. The main features of HMMs include: <br></br>

          1. States: HMMs are based on a set of states, each of which represents a particular situation or condition. <br></br>

          2. Transitions: The transitions between the states are determined by probabilities. These probabilities can be estimated from data or can be specified based on expert knowledge. <br></br>

          3. Observations: HMMs are called "hidden" because the states are not directly observable. Instead, each state generates a set of observations or signals, which can be measured or observed. <br></br>

          4. Emission probabilities: The probability of each observation being generated by each state is called the emission probability. These probabilities can also be estimated from data or specified based on expert knowledge. <br></br>

          5. Initialization: The initial state of the model is determined by probabilities. This can also be estimated from data or specified based on expert knowledge. <br></br>

          6. Training: HMMs can be trained using an algorithm called the Baum-Welch algorithm, which is a type of expectation-maximization algorithm that adjusts the transition and emission probabilities to maximize the likelihood of the observed data. <br></br>

          7. Decoding: HMMs can be used for decoding or inference, which involves estimating the most likely sequence of states that generated the observed data. <br></br>

          8. Applications: HMMs are widely used in speech recognition, natural language processing, bioinformatics, and other fields where sequential data is common. They are particularly useful for modeling time series data and for making predictions based on past observations. <br></br>
        </p>
        <h3 className="expt-sub-heading">
          <ol>
            <a href="https://colab.research.google.com/drive/1dLboGKNlYJ5COboDM3rSW5onoQtpmtWb#scrollTo=Pl4o8q7QrPW7" target="_blank" rel="noreferrer">
              <Button> Code </Button>
            </a>
            <br></br>
            <a href="https://pgm.kjsieit.com/theory/module4/mod4_part1.html" target="_blank" rel="noreferrer">
              <Button> Theory </Button>
            </a>
          </ol>
        </h3>
        <h3 className="expt-sub-heading">
          <u>Questions</u>
        </h3>
        <ol>
          <li>How Hidden Markov Model is critical for predictions</li>
          <li>HMM and Markov Model difference</li>
          <li>How are Markov Models implemented? What is the real life use of HMM?</li>
        </ol>
      </div>
    );
  }
}
